import { DIMENSIONS } from '@canalplus/dive-utils';
import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { applicationResizeSelector } from '../../../store/slices/application-selectors';
import { isPhoneResolutionSelector } from '../../../store/slices/ui-selectors';
import CoverImage from '../../CoverImage/CoverImage';
import type { CoverV5Data } from '../types';
import styles from './CoverV5.css';

export interface ICoverProps {
  cover: CoverV5Data;
}

function CoverV5({ cover }: ICoverProps): JSX.Element {
  const resizeMode = useInvariantSelector(applicationResizeSelector);
  const isCompact = useSelector(isPhoneResolutionSelector);

  return (
    <section className={styles['cover--v5']} data-testid="CoverV5">
      <CoverImage
        dimensions={DIMENSIONS.COVER_IMAGE}
        image={{ default: cover.image, mobile: cover.imageMobile }}
        onClickData={cover}
        title={cover.alt}
        isMobileOnly={isCompact}
        isDesktopOnly={!isCompact}
        resizeMode={resizeMode}
        isArtDirection
      />
    </section>
  );
}

export default CoverV5;
