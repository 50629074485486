import type { JSX, ReactNode } from 'react';
import { createContext, useCallback, useMemo, useReducer } from 'react';
import type { Option } from '../../../../../components/Dropdown/Dropdown';
import { LandingSearchActionType } from './actions';
import landingSearchReducer from './reducer';

export type LandingSearchContextType = {
  searchInput: string;
  searchResults: Option[];
  selectedOption?: Option;
  updateSearchInput: (searchInput: string) => void;
  updateSearchResults: (searchResults: Option[]) => void;
  updateSelectedOption: (selectedOption: Option) => void;
};

const LandingSearchContext = createContext<
  LandingSearchContextType | undefined
>(undefined) as React.Context<LandingSearchContextType>;

function LandingSearchProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [
    { searchInput, searchResults, selectedOption },
    landingSearchDispatcher,
  ] = useReducer(landingSearchReducer, {
    searchInput: '',
    searchResults: [],
  });

  const updateSearchInput = useCallback(
    (updatedSearchInput: string) =>
      landingSearchDispatcher({
        type: LandingSearchActionType.UpdateSearchInput,
        payload: { searchInput: updatedSearchInput },
      }),
    [landingSearchDispatcher],
  );

  const updateSearchResults = useCallback(
    (updatedSearchResults: Option[]) =>
      landingSearchDispatcher({
        type: LandingSearchActionType.UpdateSearchResults,
        payload: { searchResults: updatedSearchResults },
      }),
    [landingSearchDispatcher],
  );

  const updateSelectedOption = useCallback(
    (updatedSelectedOption: Option) =>
      landingSearchDispatcher({
        type: LandingSearchActionType.UpdateSelectedOption,
        payload: { selectedOption: updatedSelectedOption },
      }),
    [landingSearchDispatcher],
  );

  const value: LandingSearchContextType = useMemo(
    () => ({
      searchInput,
      searchResults,
      selectedOption,
      updateSearchInput,
      updateSearchResults,
      updateSelectedOption,
    }),
    [
      searchInput,
      searchResults,
      selectedOption,
      updateSearchInput,
      updateSearchResults,
      updateSelectedOption,
    ],
  );

  return (
    <LandingSearchContext.Provider value={value}>
      {children}
    </LandingSearchContext.Provider>
  );
}

LandingSearchProvider.displayName = 'LandingSearchProvider';

export { LandingSearchContext, LandingSearchProvider };
