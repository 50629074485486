import type { JSX, JSXElementConstructor, Key } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntersectionObserver } from '../../helpers/hooks/useIntersectionObserver';

export type KeyedItemProps = {
  key: Key;
};

export type LazyLoaderV2Props<ItemProps extends KeyedItemProps> = {
  itemsProps: ItemProps[];
  component: JSXElementConstructor<Omit<ItemProps, 'key'>>;
  initialDisplayCount: number;
  increment: number;
};

export function LazyLoaderV2<ItemProps extends KeyedItemProps>({
  itemsProps,
  component: Component,
  initialDisplayCount,
  increment,
}: LazyLoaderV2Props<ItemProps>): JSX.Element {
  const [count, setCount] = useState(initialDisplayCount);
  const [sentinelIsEnabled, setSentinelIsEnabled] = useState(true);
  const timeoutId = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    setCount(initialDisplayCount);
  }, [initialDisplayCount]);

  useEffect(() => {
    // Enable sentinel after the new item is added
    setSentinelIsEnabled(true);
  }, [count, setSentinelIsEnabled]);

  const onIntersect = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    // Need disable sentinel when add new item. The useIntersectionObserver need to be initialized when change dom content
    setSentinelIsEnabled(false);
    setCount((value) => value + increment);
  }, [setCount, increment, setSentinelIsEnabled]);

  const { refCallback } = useIntersectionObserver({
    threshold: 0,
    onIntersect,
  });

  return (
    <>
      <div
        style={{
          // Need a minimum height for content to place sentinel outside screen and avoid display all items at first render.
          // It's because items (strate) has no initial height before is loaded
          minHeight: `100vh`,
          height: 'auto',
          width: '100%',
        }}
      >
        {itemsProps.slice(0, count).map(({ key, ...rest }) => (
          <Component key={key} {...rest} />
        ))}
      </div>
      {sentinelIsEnabled && count < itemsProps.length && (
        <div
          style={{
            height: '1px',
            width: '1px',
          }}
          ref={refCallback}
        />
      )}
    </>
  );
}
